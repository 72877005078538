import dayjs from 'dayjs';
import { createAction } from 'redux-actions';
import { getLunarCalendar } from '@wowmaking/birthchart';
import type { LunarCalendarData, ZodiacSign } from '@wowmaking/birthchart';

import { AppThunk, AppDispatch } from 'store';
import { getISODate } from 'utils/date';
import { getLunarPredictions, getMoonPhase } from 'api/lunar-calendar';

import { getAstroInputs } from '../birth-chart/actions';

import { TYPES } from './types';

export const setPredictions = createAction(TYPES.SET_PREDICTIONS);
export const setWidget = createAction(TYPES.SET_WIDGET);
export const setSignData = createAction<LunarCalendarData>(TYPES.SET_SIGN_DATA);

export const initLunarCalendar = (): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch) => {
    const sign = await dispatch(getMoonSignForToday());

    if (!sign) {
      return;
    }

    const [moonPhase, predictions] = await Promise.all([getMoonPhase(sign), getLunarPredictions(sign)]);

    if (moonPhase) {
      dispatch(setWidget(moonPhase));
    }

    if (predictions.length) {
      dispatch(setPredictions(predictions));
    }
  };
};

export const getMoonSignForToday = (): AppThunk<Promise<ZodiacSign | null>> => {
  return async dispatch => {
    const signData = await dispatch(getSignData());
    return signData?.[getISODate()] || null;
  };
};

export const getSignData = (): AppThunk<Promise<LunarCalendarData | null>> => {
  return async (dispatch, getState) => {
    const {
      lunarCalendar: { signData, signDataUpdatedAt },
    } = getState();

    if (!signDataUpdatedAt || dayjs(signDataUpdatedAt).add(1, 'hour').isBefore(dayjs())) {
      const { current } = await dispatch(getAstroInputs());
      const res = await getLunarCalendar({ current });

      if (res.success) {
        dispatch(setSignData(res.data));
        return res.data;
      }

      return signData;
    }

    return signData;
  };
};
