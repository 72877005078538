import api from '..';

import { Location } from './interfaces';

const APPROXIMATE_LOCATION = '/approximate-location';

export const getApproximateLocation = async (): Promise<Location | null> => {
  let result = null;

  try {
    result = await api.fetch(APPROXIMATE_LOCATION, {
      method: 'GET',
    });
  } catch (err) {
    console.log('ERROR getApproximateLocation', err);
  }

  return result;
};
