import dayjs from 'dayjs';

import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  predictions: [],
  widget: {
    moon_phase: '',
    sign: null,
    moon_phases_dates: [],
    ritual: null,
  },
  signData: null,
  signDataUpdatedAt: null,
};

export default function (state = initialState, action: ActionTypes) {
  switch (action.type) {
    case TYPES.SET_PREDICTIONS:
      return {
        ...state,
        predictions: action.payload,
      };

    case TYPES.SET_WIDGET:
      return {
        ...state,
        widget: action.payload,
      };

    case TYPES.SET_SIGN_DATA:
      return {
        ...state,
        signData: action.payload,
        signDataUpdatedAt: dayjs().format(),
      };

    default:
      return state;
  }
}
