import type { ZodiacSign } from '@wowmaking/birthchart';

import api from '../index';

import { MoonPhase, LunarPrediction } from './interfaces';

const MOON_CALENDAR = '/moon-calendar';

export const getMoonPhase = async (sign: ZodiacSign): Promise<MoonPhase | null> => {
  try {
    const res = api.fetch(`${MOON_CALENDAR}/widget?sign=${sign}`, {
      method: 'GET',
    });

    return res || null;
  } catch (err) {
    console.log('ERROR getMoonPhase', err);
  }

  return null;
};

export const getLunarPredictions = async (sign: ZodiacSign): Promise<LunarPrediction[]> => {
  try {
    const res = await api.fetch(`${MOON_CALENDAR}/predictions?sign=${sign}`, {
      method: 'GET',
    });

    return res?.length ? res : [];
  } catch (err) {
    console.log('ERROR getLunarPredictions', err);
  }

  return [];
};
