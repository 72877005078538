import { EphemerisPlanet, HadesHouse, NatalHoroscopePlanet } from '@wowmaking/birthchart';

import type { NatalChartsPlanet, NatalChartsHouse } from 'api/natal-charts/interfaces';
import type { PlaceValue } from 'interfaces/birth-chart';

import { PROFILE_TYPES } from '../profile/types';

export enum TYPES {
  SET_SDK_INIT_COMPLETE = 'BIRTH-CHART/SET_SDK_INIT_COMPLETE',
  SET_ONBOARDING_DONE = 'BIRTH-CHART/SET_ONBOARDING_DONE',
  SET_ONBOARDING_STEP = 'BIRTH-CHART/SET_ONBOARDING_STEP',
  SET_BIRTH_PLACE = 'BIRTH-CHART/SET_BIRTH_PLACE',
  SET_BIRTH_TIME = 'BIRTH-CHART/SET_BIRTH_TIME',
  SET_CURRENT_LOCATION = 'BIRTH-CHART/SET_CURRENT_LOCATION',
  SET_HOUR_FORMAT_BIRTH_TIME = 'BIRTH-CHART/SET_HOUR_FORMAT_BIRTH_TIME',
  SET_DATA = 'BIRTH-CHART/SET_DATA',
  SET_CONTENT = 'BIRTH-CHART/SET_CONTENT',
  SET_REFRESHED = 'BIRTH-CHART/SET_REFRESHED',
  RESET = 'BIRTH-CHART/RESET',
}

export interface State {
  sdkInitComplete: boolean;
  onboardingDone: boolean;
  step: number;
  needToRefreshBirthChart: boolean;
  birthPlace: PlaceValue;
  birthTime: string;
  birthHourFormatTime: string;
  natalPlanets: EphemerisPlanet[];
  natalHouses: HadesHouse[];
  planetsWithSignsAndHouses: NatalHoroscopePlanet[];
  planetsContent: NatalChartsPlanet[];
  housesContent: NatalChartsHouse[];
}

interface SetSdkInitComplete {
  type: typeof TYPES.SET_SDK_INIT_COMPLETE;
}

interface SetOnboardingDone {
  type: typeof TYPES.SET_ONBOARDING_DONE;
  payload: undefined;
}

interface SetOnboardingStep {
  type: typeof TYPES.SET_ONBOARDING_STEP;
}

interface SetRefreshed {
  type: typeof TYPES.SET_REFRESHED;
}

interface SetBirthPlace {
  type: typeof TYPES.SET_BIRTH_PLACE;
  payload: PlaceValue;
}

interface SetBirthTime {
  type: typeof TYPES.SET_BIRTH_TIME;
  payload: string;
}

interface SetHourFormatBirthTime {
  type: typeof TYPES.SET_HOUR_FORMAT_BIRTH_TIME;
  payload: string;
}

interface SetBirthChartData {
  type: typeof TYPES.SET_DATA;
  payload: SetBirthChartDataPayload;
}

export interface SetBirthChartDataPayload {
  natalPlanets: EphemerisPlanet[];
  natalHouses: HadesHouse[];
  planetsWithSignsAndHouses: NatalHoroscopePlanet[];
}

interface SetBirthChartContent {
  type: typeof TYPES.SET_CONTENT;
  payload: SetBirthChartContentPayload;
}

export interface SetBirthChartContentPayload {
  planetsContent: NatalChartsPlanet[];
  housesContent: NatalChartsHouse[];
}

interface ResetBirthChart {
  type: typeof TYPES.RESET;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

export type ActionType =
  | SetSdkInitComplete
  | SetOnboardingDone
  | SetOnboardingStep
  | SetRefreshed
  | SetBirthPlace
  | SetBirthTime
  | SetHourFormatBirthTime
  | SetBirthChartData
  | SetBirthChartContent
  | ResetBirthChart
  | ResetUserData;
