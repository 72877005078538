import type { PlanetId } from '@wowmaking/birthchart';

export enum TYPES {
  SET = 'RETROGRADE/SET',
}

type Duration = {
  from: string;
  to: string;
};

export interface Planet {
  id: PlanetId;
  name: {
    en: string;
    ru: string;
  };
  influence: string;
  duration: Duration[];
}

export interface State {
  retrogradePlanets: Planet[];
  planets: Planet[];
}

interface Set {
  type: typeof TYPES.SET;
  payload: Planet[];
}

export type ActionTypes = Set;
