import _ from 'lodash';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import RNRestart from 'react-native-restart';
import { createAction } from 'redux-actions';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';

import { AppDispatch, AppGetState } from 'store';
import Analytics from 'analytics';
import { isStandalone } from 'utils/pwa';
import { toggleOverlayLoaderActivity } from 'store/app/actions';
import { setPalmResult } from 'store/palm-reading-daily/actions';

import api from '../../api';
import * as AuthAPI from '../../api/auth';
import { ProfileData } from '../../api/profile/interfaces';
import { setProfile, resetUserProfileData } from '../profile/actions';
import { setOnboardingCompleted } from '../onboarding/actions';

import { AUTH_TYPES } from './types';

const setUserCredentials = createAction(AUTH_TYPES.SET_USER_CREDENTIALS);
const setWebUUID = createAction(AUTH_TYPES.SET_WEB_UUID);
export const resetAuthData = createAction(AUTH_TYPES.RESET_DATA);

const IDFM_STORAGE_KEY = '@RNMV/IDFM';

let isAuthStarted = false;
export const initAuth = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID },
    } = getState();

    isAuthStarted = true;

    try {
      const uuid = webUUID || (await DeviceInfo.getUniqueId());

      const params: Record<string, string | boolean> = {
        uuid,
      };

      if (webUUID) {
        params.isPWA = isStandalone();
      }

      Analytics.trackEvent('auth', 'start', params);
      const response = await AuthAPI.auth(uuid);
      dispatch(processAuthResponse(response));
      Analytics.trackEvent('auth', 'success');
    } catch (error: any) {
      console.log('Error while authentication', error);
      Analytics.trackEvent('auth', 'error', { error: error?.message || error });
    }
  };
};

const handleWebAuth = (response: any) => {
  return (dispatch: AppDispatch) => {
    dispatch(processAuthResponse(response));

    const uuid = response?.profile?.device_id;
    Analytics.trackEvent('app', 'web_uuid', { uuid, mode: 'email' });
    Analytics.setUserProperty('web_source', 'sub');

    dispatch(setWebUUID(uuid));

    if (Platform.OS === 'web') {
      window.localStorage.setItem(IDFM_STORAGE_KEY, uuid);
    }
  };
};

export const signUpWeb = () => {
  return () => {
    if (Platform.OS !== 'web') {
      return false;
    }
    const idfm = window.localStorage.getItem('@RNMV/IDFM');
    const url: any = queryString.stringifyUrl({
      url: process.env.REACT_APP_WEB_SUB_URL as unknown as string,
      query: { idfm },
    });
    window.location = url;
  };
};

const processAuthResponse = (response: ProfileData) => {
  return (dispatch: AppDispatch) => {
    if (response) {
      const accessToken = _.get(response, 'profile.access_token', null);
      if (accessToken) {
        api.setToken(accessToken);
      }
      dispatch(setProfile(response));

      const userCredentials = {
        id: _.get(response, 'profile.id', ''),
        accessToken: _.get(response, 'profile.access_token', ''),
      };

      dispatch(setUserCredentials(userCredentials));
    }
  };
};

export const authWithOTP = (code: string) => {
  return async (dispatch: AppDispatch) => {
    const response = await AuthAPI.authWithOTP(code);
    dispatch(handleWebAuth(response));
    return response;
  };
};

export const finalizeWebAuth = () => {
  return (dispatch: AppDispatch) => {
    dispatch(setOnboardingCompleted());
    dispatch(setPalmResult(null));
    setTimeout(() => {
      RNRestart.Restart();
    }, 200); // TODO replace with persistor flush
  };
};

export function processWebUUID(uuid: string, isDeferred?: boolean, source?: string | null) {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { removeIdfmFromUrl },
      },
    } = getState();

    if (Platform.OS === 'web' && removeIdfmFromUrl) {
      const query = queryString.parse(window?.location?.search);
      if (query?.idfm) {
        delete query.idfm;
        createBrowserHistory().replace(
          queryString.stringifyUrl({
            url: window.location.pathname,
            query,
          }),
        );
      }
    }

    const { webUUID } = getState().auth;

    if (uuid === webUUID || !uuid) {
      return false;
    }

    dispatch(toggleOverlayLoaderActivity(true));
    try {
      Analytics.trackEvent('app', 'web_uuid', { uuid, mode: isDeferred ? 'deferred' : 'link' });
      if (source) {
        Analytics.setUserProperty('web_source', source);
      }
      dispatch(resetUserProfileData());
      dispatch(resetAuthData());
      dispatch(setWebUUID(uuid));

      if (isAuthStarted) {
        setTimeout(() => {
          RNRestart.restart();
        }, 700);
      }
      return true;
    } catch (e) {
      console.log('> Process web uuid error: ', e);
    }
    dispatch(toggleOverlayLoaderActivity(false));
  };
}
