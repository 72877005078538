import { createAction } from 'redux-actions';
import { isEmpty, shuffle, some, without } from 'lodash';
import dayjs from 'dayjs';
import { getCompatibility as getPlanetaryReport, DEFAULT_DATE, DEFAULT_TIME } from '@wowmaking/birthchart';
import type { PlanetaryCompatibilityItem } from '@wowmaking/birthchart';

import Analytics from 'analytics';
import * as ROUTES from 'constants/routes';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { back, replace } from 'store/navigation/actions';
import {
  BIRTHCHARTS_COM8TY_ONB_PAGES_IDS,
  DAILY_LOVE_HOROSCOPES_LIST,
  DEFAULT_BIRTHCHARTS_COM8TY_ONB_FLOW,
} from 'screens/birth-charts-compatibility/constants';
import { updateProfile } from 'store/profile/actions';
import { selectZodiacSign } from 'store/profile/selectors';
import { getCompatibility } from 'api/compatibility';

import { getAstroInputs } from '../birth-chart/actions';

import { CompatibilityProfileData, TYPES } from './types';
import { getPartnerZodiacSign } from './selectors';

const setCompatibilityInsights = createAction(TYPES.SET_COMPATIBILITY_INSIGHTS);
const setLoveHoroscopeActiveId = createAction(TYPES.SET_LOVE_HOROSCOPE_ACTIVE_ID);
const setLoveHoroscopesPlan = createAction(TYPES.SET_LOVE_HOROSCOPES_PLAN);
const setOnboardingFlow = createAction(TYPES.SET_ONBOARDING_FLOW);
const setOnboardingIsCompleted = createAction(TYPES.SET_ONBOARDING_IS_COMPLETED);
const setOnboardingStep = createAction(TYPES.SET_ONBOARDING_STEP);
const setResult = createAction(TYPES.SET_RESULT);
export const setWidgetVisible = createAction(TYPES.SET_WIDGET_VISIBLE);
export const updatePartnerData = createAction(TYPES.UPDATE_PARTNER_DATA);
const setPlanetaryReport = createAction<PlanetaryCompatibilityItem[]>(TYPES.SET_PLANETARY_REPORT);

export const initBirthChartsCompatibility = (): AppThunk => (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    birthChart: { birthPlace, birthTime },
    birthChartsCompatibility: { onboardingIsCompleted },
    profile: {
      profileData: { name, date },
    },
  } = getState();

  if (onboardingIsCompleted) {
    dispatch(generateDailyLoveHoroscopes());
  } else {
    const requiredUserParams = [name, date, birthPlace, birthTime];
    const isFullUserProfile = !some(requiredUserParams, isEmpty);
    dispatch(
      setOnboardingFlow(
        isFullUserProfile
          ? DEFAULT_BIRTHCHARTS_COM8TY_ONB_FLOW
          : [BIRTHCHARTS_COM8TY_ONB_PAGES_IDS.USER_INFO, ...DEFAULT_BIRTHCHARTS_COM8TY_ONB_FLOW],
      ),
    );
  }
};

export const completeOnboarding = (): AppThunk => (dispatch: AppDispatch) => {
  Analytics.trackEvent('BirthChartsCompatibilityOnb', 'Completed');
  dispatch(setOnboardingIsCompleted());
  dispatch(generateDailyLoveHoroscopes());
  replace(ROUTES.BIRTHCHARTS_COM8TY);
};

export const goToNextOnboardingStep = (): AppThunk => (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    birthChartsCompatibility: { onboardingFlow, onboardingStep },
  } = getState();

  const nextStepIndex = onboardingStep !== null ? onboardingFlow.indexOf(onboardingStep) + 1 : 0;
  const page = onboardingFlow[nextStepIndex];

  if (!page) {
    return dispatch(completeOnboarding());
  }

  dispatch(setOnboardingStep(page));
};

export const goToPrevOnboardingStep = (): AppThunk => (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    birthChartsCompatibility: { onboardingFlow, onboardingStep },
  } = getState();

  const prevStepIndex = onboardingStep !== null ? onboardingFlow.indexOf(onboardingStep) - 1 : -1;
  const page = onboardingFlow[prevStepIndex];

  if (!page) {
    return back();
  }

  dispatch(setOnboardingStep(page));
};

export const updateUserData =
  (data: CompatibilityProfileData): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      await dispatch(updateProfile({ name: data.name, date: data.date, place: data.place, time: dayjs(data.time).format('HH:mm') }));
    } catch {
      console.log('ERROR update user profile');
    }
  };

export const generateDailyLoveHoroscopes = (): AppThunk => (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    birthChartsCompatibility: { loveHoroscopesActiveId, loveHoroscopesPlan, loveHoroscopesUpdateDate },
  } = getState();

  if (!loveHoroscopesActiveId || !loveHoroscopesUpdateDate || !loveHoroscopesPlan.length) {
    const horoscopeIds = shuffle(DAILY_LOVE_HOROSCOPES_LIST);
    dispatch(setLoveHoroscopesPlan(horoscopeIds));
    return;
  }

  if (!dayjs().isSame(dayjs(loveHoroscopesUpdateDate), 'day')) {
    const currentHoroscopeItemIdx = loveHoroscopesPlan.findIndex(item => item === loveHoroscopesActiveId);
    const nextHoroscopeItemId = loveHoroscopesPlan[currentHoroscopeItemIdx + 1];
    if (nextHoroscopeItemId) {
      dispatch(setLoveHoroscopeActiveId(nextHoroscopeItemId));
    } else {
      dispatch(updateLoveHoroscopesPlan());
    }
  }
};

export const updateLoveHoroscopesPlan = (): AppThunk => (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    birthChartsCompatibility: { loveHoroscopesActiveId },
  } = getState();

  const newHoroscopesPlan = [...shuffle(without(DAILY_LOVE_HOROSCOPES_LIST, loveHoroscopesActiveId)), loveHoroscopesActiveId];
  dispatch(setLoveHoroscopesPlan(newHoroscopesPlan));
};

export const loadBirthChartsCompatibilityData = (): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const state = getState();

      const userSign = selectZodiacSign(state);
      const partnerSign = getPartnerZodiacSign(state);

      if (!userSign || !partnerSign) {
        return;
      }

      const data = await getCompatibility(userSign, partnerSign);

      if (isEmpty(data)) {
        return;
      }

      const result = {
        compatibilityLevelPercent: data.compatibilityLevel?.percent,
        aspects: [
          {
            percent: data.compatibility_aspects_love_percent,
            id: 'love',
          },
          {
            percent: data.wheel_of_balance_sexual_percent,
            id: 'sex',
          },
          {
            percent: data.compatibility_aspects_marriage_percent,
            id: 'family',
          },
          {
            percent: data.compatibility_aspects_teamwork_percent,
            id: 'friendship',
          },
        ],
        signs: {
          user: userSign.toLowerCase(),
          partner: partnerSign.toLowerCase(),
        },
      };

      dispatch(setResult(result));
      dispatch(setCompatibilityInsights(data.overview));
    } catch (error) {
      console.warn('ERROR load birth charts compatibility result', error);
    }
  };
};

export const updatePartnerProfile =
  (data: CompatibilityProfileData): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      birthChartsCompatibility: { partnerData },
    } = getState();

    const isNeedUpdate = some(Object.keys(partnerData), param => partnerData[param] !== data[param]);

    if (isNeedUpdate) {
      Analytics.trackEvent('BirthChartsCompatibility_PartnersInfo', 'Updated');
      dispatch(updatePartnerData(data));

      if (data.date !== partnerData.date || data.time !== partnerData.time || data.place !== partnerData.place) {
        dispatch(loadPlanetaryReport());
      }

      if (data.date !== partnerData.date || data.place !== partnerData.place) {
        dispatch(updateLoveHoroscopesPlan());
      }

      if (data.date !== partnerData.date) {
        await dispatch(loadBirthChartsCompatibilityData());
      }
    }
  };

export const loadPlanetaryReport = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const {
      birthChartsCompatibility: {
        partnerData: { date: partnerBirthDate, time: partnerBirthTime, place: partnerBirthLocation },
      },
    } = getState();

    const {
      birth: userBirth,
      current: { location },
    } = await dispatch(getAstroInputs());

    const res = await getPlanetaryReport({
      userBirth,
      partnerBirth: {
        date: partnerBirthDate || DEFAULT_DATE,
        time: partnerBirthTime || DEFAULT_TIME,
        location: partnerBirthLocation || location,
      },
    });

    if (res.success) {
      const planetaryReport = res.data;
      dispatch(setPlanetaryReport(planetaryReport));
    }
  };
};
