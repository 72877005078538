import _ from 'lodash';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

const v = DeviceInfo.getVersion();

export const APP_NAME = Platform.select({
  ios: 'Astroline',
  android: 'Astroline',
  web: process.env.REACT_APP_TITLE,
});

export const REACT_REGISTRY_NAME = Platform.select({
  ios: 'astroline',
  android: 'fortunescope',
});

export const BUNDLE_ID = Platform.select({
  ios: 'com.horoastroline',
  android: 'com.fortunescope',
  default: '',
});

export const IOS_APP_ID = '1448364002';
export const IS_DEV_BUILD = _.indexOf(v, '-') !== -1 || __DEV__;

export const MERCHANT_ID = Platform.select({
  ios: 'merchant.today.astroline.sub',
  android: IS_DEV_BUILD ? 'api_pk_01b785750b7946b5a594a7f215fc44e4' : 'api_pk_56b64b84adec481aa2ec4ca419a52dc1',
}) as string;

export const MAGNUS_TOKEN = Platform.select({
  android: 'IudAA6Es6Yaa1HlNvcAW4kYF96y8i5AT',
  ios: 'hUfy4xNqbXL82C9No8viz1FzgYoJyxx9',
  web: process.env.REACT_APP_MAGNUS_TOKEN,
  default: '',
});

export const FACEBOOK_APP_ID = Platform.select({
  ios: '319497672281889',
  android: '306292439917588',
}) as string;

export const AMPLITUDE_KEY = Platform.select({
  android: '5ba43c9fac3d82de23dc89d79114bb3e',
  ios: 'f3affa2e0e86b18d28d791c395d2b711',
  default: '',
});

export const GOOGLE_CLOUD_API_KEY = Platform.select({
  android: 'AIzaSyAFkG9VjObcDheHl_7ToMBz-0A3ENo42UE',
  ios: 'AIzaSyBUsLvdrbGeXYZRysM_AAlLK-c0R4-oVts',
});

export const TITLE = Platform.select({
  android: 'Astroline',
  ios: 'Astroline',
  web: process.env.REACT_APP_TITLE,
});

export const SUB_CANCEL_LINK = Platform.select({
  ios: 'itms-apps://apps.apple.com/account/subscriptions',
  android: 'https://play.google.com/store/account/subscriptions',
});

const WEB_LINK_DEV_DEFINER = IS_DEV_BUILD ? '-stage' : '';

const WEB_APP_URL = Platform.OS === 'web' ? process.env.REACT_APP_WEB_SUB_URL : `https://sub${WEB_LINK_DEV_DEFINER}.astroline.today`;

export const WEB_SUB_CANCEL_LINK = `${WEB_APP_URL}/terminate?click_source=app&email=`;

export const ASB_URL = `https://app${WEB_LINK_DEV_DEFINER}.astroline.today/asb`;

export const SUPPORT_TOPIC = Platform.select({
  android: '[Astroline]',
  ios: '[Astroline]',
  web: `[${process.env.REACT_APP_TITLE}]`,
});

export const ZENDESK_TOKEN =
  Platform.OS === 'web' ? process.env.REACT_APP_ZENDESK_TOKEN : '74f1278a060753c72a41192ad686820c7199cea526dd8881965d72eb02a274bb';

export const HELPDESK_TEAM_ID = Platform.select({
  web: 'f432ea87-bc31-4b82-80db-82673a4bc825',
  ios: '9ca18b09-b125-4a50-a9c1-a54d191cdf6b', // Astroline
  android: 'c45e666a-cbaa-4484-85c2-5833370ca286',
}) as string;

export const HELPDESK_REQUIRED_SUBSCRIPTIONS_TAGS = {
  app: '0ba6e81b-f106-4333-921e-de1b657b81e3',
  web: '06d2241a-97a8-4e16-9dc5-245d72bc1488',
} as any;

export const SUPPORT_EMAIL = Platform.select({
  ios: 'astroline.ios@appdesk.zendesk.com',
  android: 'astroline.android@appdesk.zendesk.com',
  web: process.env.REACT_APP_SUPPORT_EMAIL,
});

export const STORE_LINK = Platform.select({
  ios: `itms-apps://apps.apple.com/app/id${IOS_APP_ID}?action=write-review`,
  android: `https://play.google.com/store/apps/details?id=${BUNDLE_ID}`,
});

export const ADJUST_APP_SECRET: [number, number, number, number, number] = [1, 902643034, 1646348973, 1751480555, 1362254479];

export const ADJUST_APP_TOKEN = 'nty2gry6jvuo';

export const ADJUST_UNIVERSAL_LINK = 't96d.adj.st';

export const ADJUST_EVENTS = {
  view_product: 'dtqu45',
  subscription_success: '5tv8g2',
  app_activity: '7s2ee2',
};

export const DO_NOT_REMEMBER = 2400;

export const ADJUST_FAMILY_SHARE_QUERY_PARAM = 'family_share';
export const ADJUST_OFFER_QUERY_PARAM = 'offer';

export const TRUST_REDIRECT_URI = '/review-success.html';

// TODO: шо здесь?
export const TRUST_PILOT_URL = 'https://www.trustpilot.com/evaluate/astroline.today';
