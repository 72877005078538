import api from '..';

import { NatalChartsPlanet, NatalChartsHouse } from './interfaces';

const PLANETS = '/birthchart/planets';
const HOUSES = '/birthchart/houses';

export const getPlanetsContent = async (): Promise<NatalChartsPlanet[]> => {
  try {
    const res = await api.fetch(PLANETS, {
      method: 'GET',
    });

    return res?.length ? res : [];
  } catch (err) {
    console.log('ERROR getPlanetsContent', err);
  }

  return [];
};

export const getHousesContents = async (): Promise<NatalChartsHouse[]> => {
  try {
    const res = await api.fetch(HOUSES, {
      method: 'GET',
    });

    return res?.length ? res : [];
  } catch (err) {
    console.log('ERROR getHousesContents', err);
  }

  return [];
};
