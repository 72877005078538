import { TYPES } from './types';
import type { State, ActionTypes } from './types';

const initialState: State = {
  currentLocation: null,
  currentPlanets: [],
  dailyTransits: null,
  nextTransits: null,
  transitsContent: [],
  aspectsContent: [],
  astroeventTransit: {
    title: '',
    description: '',
    duration: '',
    connectionCounter: 0,
    transit: null,
  },
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };

    case TYPES.SET_TRANSITS_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.SET_TRANSITS_CONTENT:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.SET_ASTROEVENT_TRANSIT:
      return {
        ...state,
        astroeventTransit: action.payload,
      };

    default:
      return state;
  }
}
