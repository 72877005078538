import { createSelector } from 'reselect';
import _ from 'lodash';
import dayjs from 'dayjs';

import { RootState } from 'store';
import { HoroscopeTypes } from 'constants/horoscopes-names';
import { PERIODS_IDS } from 'constants/periods';

import { MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD } from './constants';
import type { UserHoroscopeV2Period } from './types';

const getHoroscope = (state: RootState) => state.horoscope;

export const selectZodiacSigns = createSelector([getHoroscope], ({ allSigns }) => (allSigns ? _.get(allSigns, HoroscopeTypes.zodiac) : []));

export const selectUserHoroscopeV2 = (state: RootState, period: UserHoroscopeV2Period) =>
  state.horoscope.userHoroscopesV2.find(item => {
    if (item.period !== MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD[period]) {
      return false;
    }

    switch (period) {
      case PERIODS_IDS.TODAY:
        return dayjs().isSame(dayjs(item.date), 'day');
      case PERIODS_IDS.TOMORROW:
        return dayjs().add(1, 'day').isSame(dayjs(item.date), 'day');
      case PERIODS_IDS.WEEK:
        return dayjs().day(0).isSame(dayjs(item.date).day(0), 'day');
      case PERIODS_IDS.MONTH:
        return dayjs().isSame(dayjs(item.date), 'month');
      case PERIODS_IDS.YEAR:
        return dayjs().isSame(dayjs(item.date), 'year');
    }
  }) || null;

export const selectHasDataToGenerateUserHoroscopeV2 = (state: RootState) =>
  !!(
    state.birthChart.sdkInitComplete &&
    state.birthChart.birthPlace?.lat &&
    state.birthChart.birthPlace?.lon &&
    state.birthChart.natalPlanets.length &&
    state.horoscope.userHoroscopes[HoroscopeTypes.zodiac].length
  );

export const selectPromptId = (state: RootState, period: UserHoroscopeV2Period): number | string => {
  const {
    remoteConfig: {
      remoteConfigParams: { summaryWidgetConfig, todayContentTipsEnabled, todayContentDoDontEnabled, todayContentPid },
    },
  } = state;

  const itemPeriod = MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD[period];

  if (itemPeriod === 'day') {
    if (todayContentTipsEnabled && todayContentDoDontEnabled) {
      return todayContentPid?.horoscopesTipsDoDont;
    }

    if (todayContentTipsEnabled && !todayContentDoDontEnabled) {
      return todayContentPid?.horoscopesTips;
    }

    if (!todayContentTipsEnabled && todayContentDoDontEnabled) {
      return todayContentPid?.horoscopesDoDont;
    }
  }

  return summaryWidgetConfig?.pid?.[itemPeriod];
};
