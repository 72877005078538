import { createAction } from 'redux-actions';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';
import { getBirthChart, setConfig, DEFAULT_LOCATION } from '@wowmaking/birthchart';
import type { AstroInputs } from '@wowmaking/birthchart';

import Analytics from 'analytics';
import type { AppThunk } from 'store';
import { getPlanetsContent, getHousesContents } from 'api/natal-charts';
import { BIRTH_CHART_ONBOARDING_STEPS } from 'constants/onboarding-types';
import * as ROUTES from 'constants/routes';
import type { PlaceValue } from 'interfaces/birth-chart';
import { ISO_DATE_FORMAT, TIME_FORMAT } from 'utils/date';
import { getCurrentHourFormat } from 'utils/timezone';

import { replace } from '../navigation/actions';
import { updateProfile } from '../profile/actions';
import { getCurrentLocation, loadTransitsData, loadTransitsContent, generateAstroeventTransitData } from '../transits/actions';

import { SetBirthChartDataPayload, SetBirthChartContentPayload, TYPES } from './types';

export const setSdkInitComplete = createAction(TYPES.SET_SDK_INIT_COMPLETE);
export const setOnboardingDone = createAction(TYPES.SET_ONBOARDING_DONE);
export const setOnboardingStep = createAction(TYPES.SET_ONBOARDING_STEP);
export const setRefreshed = createAction(TYPES.SET_REFRESHED);
export const setBirthPlace = createAction<PlaceValue>(TYPES.SET_BIRTH_PLACE);
export const setBirthTimeAction = createAction<string>(TYPES.SET_BIRTH_TIME);
export const setHourFormatBirthTime = createAction<string>(TYPES.SET_HOUR_FORMAT_BIRTH_TIME);
export const setBirthChartData = createAction<SetBirthChartDataPayload>(TYPES.SET_DATA);
export const setBirthChartContent = createAction<SetBirthChartContentPayload>(TYPES.SET_CONTENT);
export const resetBirthChart = createAction(TYPES.RESET);

/* Init */

export const initBirthChart = (withProfileUpdate = false): AppThunk<Promise<void>> => {
  return async dispatch => {
    if (withProfileUpdate) {
      await dispatch(updateProfile());
    }
    dispatch(initBirthChartSdk());
    dispatch(loadBirthChartData());
    dispatch(loadBirthChartContent());
    dispatch(loadTransitsData()).then(() => {
      dispatch(generateAstroeventTransitData());
    });
    dispatch(loadTransitsContent());
    dispatch(setRefreshed());
  };
};

export const initBirthChartSdk = (): AppThunk => {
  return (dispatch, getState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { birthChartApiUrl },
      },
    } = getState();

    setConfig({
      apiUrl: birthChartApiUrl,
      onRequestFailed: (path, errors) => {
        Analytics.track('BirthChartService_Request_Failed', { path });
        console.warn('Birthchart service request failed:', path, errors);
      },
      onResponseWithErrors: (path, errors) => {
        Analytics.track('BirthChartService_Response_HasErrors', { path, errorsCount: errors.length });
        console.warn('Birthchart service response has errors:', path, errors);
      },
    });

    dispatch(setSdkInitComplete());
  };
};

export const loadBirthChartData = (): AppThunk<Promise<void>> => {
  return async dispatch => {
    const { birth } = await dispatch(getAstroInputs());
    const res = await getBirthChart({ birth });

    if (res.success) {
      const { natalPlanets, natalHouses, planetsWithSignsAndHouses } = res.data;

      dispatch(
        setBirthChartData({
          natalPlanets,
          natalHouses,
          planetsWithSignsAndHouses,
        }),
      );
    }
  };
};

export const loadBirthChartContent = (): AppThunk<Promise<void>> => {
  return async dispatch => {
    const [planetsContent, housesContent] = await Promise.all([getPlanetsContent(), getHousesContents()]);

    dispatch(
      setBirthChartContent({
        planetsContent,
        housesContent,
      }),
    );
  };
};

/* Onboarding */

export const checkBirthChartOnboardingState = (): AppThunk => {
  return (dispatch, getState) => {
    const {
      birthChart: { birthPlace, birthHourFormatTime, step },
    } = getState();

    if (!isEqual(birthPlace, DEFAULT_LOCATION) && birthHourFormatTime && !step) {
      dispatch(setOnboardingDone());
    }
  };
};

export const goToNextScreen = (): AppThunk => {
  return (dispatch, getState) => {
    const {
      birthChart: { step },
    } = getState();
    const stepsLength = Object.keys(BIRTH_CHART_ONBOARDING_STEPS).length - 1;

    if (step >= stepsLength) {
      dispatch(setOnboardingDone());
      replace(ROUTES.BIRTH_CHART_DASHBOARD);
    } else {
      dispatch(setOnboardingStep());
    }
  };
};

/* Setters */

export const setBirthTime =
  (time: string): AppThunk<Promise<void>> =>
  async dispatch => {
    dispatch(setBirthTimeAction(time));
    const hourFormatTime = await getCurrentHourFormat(time);
    dispatch(setHourFormatBirthTime(hourFormatTime));
  };

export const processBirthTimeData = (date: Date): AppThunk<Promise<void>> => {
  return async dispatch => {
    await dispatch(setBirthTime(dayjs(date).format('HH:mm')));
  };
};

/* Helpers */

export const getAstroInputs = (): AppThunk<
  Promise<{
    birth: AstroInputs;
    current: AstroInputs;
  }>
> => {
  return async (dispatch, getState) => {
    const {
      birthChart: { birthPlace, birthTime },
      profile: {
        profileData: { date },
      },
    } = getState();

    const currentLocation = await dispatch(getCurrentLocation());

    return {
      birth: {
        date,
        time: birthTime,
        location: birthPlace,
      },
      current: {
        date: dayjs().format(ISO_DATE_FORMAT),
        time: dayjs().format(TIME_FORMAT),
        location: currentLocation,
      },
    };
  };
};
