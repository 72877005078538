import { DEFAULT_LOCATION, DEFAULT_TIME } from '@wowmaking/birthchart';

import { PROFILE_TYPES } from '../profile/types';

import { TYPES } from './types';
import type { State, ActionType } from './types';

const initialState: State = {
  sdkInitComplete: false,
  onboardingDone: false,
  step: 0,
  needToRefreshBirthChart: false,
  birthPlace: DEFAULT_LOCATION,
  birthTime: DEFAULT_TIME,
  birthHourFormatTime: '',
  natalPlanets: [],
  natalHouses: [],
  planetsWithSignsAndHouses: [],
  planetsContent: [],
  housesContent: [],
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_SDK_INIT_COMPLETE:
      return {
        ...state,
        sdkInitComplete: true,
      };

    case TYPES.SET_ONBOARDING_DONE:
      return {
        ...state,
        onboardingDone: true,
      };

    case TYPES.SET_ONBOARDING_STEP:
      return {
        ...state,
        step: state.step + 1,
      };

    case TYPES.SET_REFRESHED:
      return {
        ...state,
        needToRefreshBirthChart: false,
      };

    case TYPES.SET_BIRTH_PLACE:
      return {
        ...state,
        birthPlace: action.payload,
        needToRefreshBirthChart: state.needToRefreshBirthChart || state.birthPlace.name !== action.payload.name,
      };

    case TYPES.SET_BIRTH_TIME:
      return {
        ...state,
        birthTime: action.payload,
        needToRefreshBirthChart: state.needToRefreshBirthChart || state.birthTime !== action.payload,
      };

    case TYPES.SET_HOUR_FORMAT_BIRTH_TIME:
      return {
        ...state,
        birthHourFormatTime: action.payload,
      };

    case TYPES.SET_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.SET_CONTENT:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.RESET:
      return initialState;

    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;

    default:
      return state;
  }
}
