import api from '..';

import { AspectDescription, TransitDescription } from './interfaces';

const TRANSITS = '/transits';
const ASPECTS = '/birthchart/aspects';

export const getTransitsContent = async (): Promise<TransitDescription[]> => {
  try {
    const res = await api.fetch(TRANSITS, {
      method: 'GET',
    });

    return res?.length ? res : [];
  } catch (err) {
    console.log('ERROR getTransitsContent', err);
  }

  return [];
};

export const getAspectsContent = async (): Promise<AspectDescription[]> => {
  try {
    const res = await api.fetch(ASPECTS, {
      method: 'GET',
    });

    return res?.length ? res : [];
  } catch (err) {
    console.log('ERROR getAspectsContent', err);
  }

  return [];
};
