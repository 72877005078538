import dayjs from 'dayjs';
import { ZodiacSign } from '@wowmaking/birthchart';

import { RootState } from 'store';
import { getZodiac } from 'utils/zodiac';

import { CompatibilityProfileData } from './types';

export const getUserProfileData = (state: RootState): CompatibilityProfileData => {
  const {
    birthChart: { birthPlace, birthTime },
    profile: {
      profileData: { name, date },
    },
  } = state;
  return {
    name,
    date,
    place: birthPlace,
    time: birthTime,
  };
};

export const getPartnerZodiacSign = (state: RootState): ZodiacSign => {
  const {
    birthChartsCompatibility: { partnerData },
  } = state;
  return ZodiacSign[getZodiac(dayjs(partnerData.date).get('month') + 1, dayjs(partnerData.date).get('date'), true)];
};
