import { createSelector } from 'reselect';

import type { RootState } from 'store';

const getAstroeventTransit = (state: RootState) => state.transits.astroeventTransit;

export const getAstroeventMessage = createSelector(
  [getAstroeventTransit],
  astroeventTransit => `${astroeventTransit.title} ${astroeventTransit.description}`,
);
