import format from 'string-format';

import { WEB_ONE_TIME_PRODUCT_CODES as ONE_TIME_PRODUCT_CODES, Currency } from 'constants/billing';
import { type Product as PaymentProduct } from 'modules/payments/interfaces/product';

import { Pack, Product, AstrologerCreditsConfig } from '../constants/interfaces';

export const convertMinutesToCredits = (minutes: number, rate: number = 60) => {
  return minutes * rate;
};

const getPricePerMinute = (pack: Pack) => {
  const { price, currency, minutesAmount } = pack;

  const pricePerMin = price / minutesAmount;

  let currencySymbol = '';

  if (currency === 'USD') {
    currencySymbol = '$';
  }

  if (currency === 'EUR') {
    currencySymbol = '€';
  }

  return `${currencySymbol}${pricePerMin.toFixed(2)}`;
};

export const getPricePerCredit = (pack: Pack, rate: number = 60) => {
  const { price, currency, minutesAmount } = pack;

  const pricePerMin = price / minutesAmount;
  const pricePerCredit = pricePerMin / rate;

  let currencySymbol = '';

  if (currency === 'USD') {
    currencySymbol = '$';
  }

  return `${currencySymbol}${pricePerCredit.toFixed(2)}`;
};

export const getPriceText = (text: string, product: Product) => {
  return format(text, product);
};

export const preparePackToProductByCurrency = (pack: Pack, currency: Currency, creditsConfig?: AstrologerCreditsConfig): Product => {
  const id = `${ONE_TIME_PRODUCT_CODES.ASTROLOGER_CONSULTATION}_${pack?.minutesAmount * 60}`;
  const credits = !creditsConfig?.enabled ? 0 : convertMinutesToCredits(pack.minutesAmount, creditsConfig?.rate);

  const p = !pack[currency]
    ? {
        id,
        pricePerMin: getPricePerMinute(pack),
        credits,
        creditsRate: creditsConfig?.rate,
        pricePerCredit: getPricePerCredit(pack, creditsConfig?.rate),
        ...pack,
      }
    : {
        ...pack,
        ...pack[currency],
        id,
        currency,
        pricePerMin: getPricePerMinute(pack),
        credits,
        creditsRate: creditsConfig?.rate,
        pricePerCredit: getPricePerCredit(pack, creditsConfig?.rate),
      };

  return {
    ...p,
    pricePerMin: getPricePerMinute(p),
  };
};

export const preparePackProductToPaymentProduct = (product: Product): PaymentProduct => {
  return {
    id: product.id,
    price: product.price,
    currency: product.currency,
    title: product.title,
    priceText: product.activePrice,
    textLineThrough: product.notActivePrice,
  };
};
