import type { LunarCalendarData } from '@wowmaking/birthchart';

import { LunarPrediction, MoonPhase } from 'api/lunar-calendar/interfaces';

export enum TYPES {
  SET_PREDICTIONS = 'LUNAR_CALENDAR/SET_PREDICTIONS',
  SET_WIDGET = 'LUNAR_CALENDAR/SET_WIDGET',
  SET_SIGN_DATA = 'LUNAR_CALENDAR/SET_SIGN_DATA',
}

type Widget =
  | {
      moon_phase: string;
      sign: null;
      moon_phases_dates: [];
      ritual: null;
    }
  | MoonPhase;

export interface State {
  predictions: LunarPrediction[];
  widget: Widget;
  signData: LunarCalendarData | null;
  signDataUpdatedAt: string | null;
}

interface SetWidget {
  type: typeof TYPES.SET_WIDGET;
  payload: MoonPhase;
}

interface SetPredictions {
  type: typeof TYPES.SET_PREDICTIONS;
  payload: LunarPrediction[];
}

interface SetSignData {
  type: typeof TYPES.SET_SIGN_DATA;
  payload: LunarCalendarData;
}

export type ActionTypes = SetWidget | SetPredictions | SetSignData;
