import DeviceProps from '@magnus/react-native-device-props';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { Linking, Platform } from 'react-native';
import { getVersion, getModel } from 'react-native-device-info';
import Rate, { AndroidMarket } from 'react-native-rate';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { generateTrustPilotLink } from 'api/trust-pilot';
import { getPromocode } from 'api/astrology-chat';
import * as GENERAL from 'constants/general';
import * as MODALS from 'constants/modals';
import * as ROUTES from 'constants/routes';
import { AppDispatch, AppGetState } from 'store';
import { showModal, closeCurrentModal } from 'store/modals/actions';
import { addActiveTodo } from 'store/astro-calendar/actions';
import { handleExternalTrigger } from 'store/astrologers/core/actions';
import { ASTRO_CALENDAR_TODO_TYPE } from 'screens/astro-calendar/constants';
import { formatMinutes } from 'utils/astrologist';
import { isErrorWithMassage } from 'utils/error';

import { TYPES, FEATURES } from './types';

export const setRateUsThanksModalVisibility = createAction(TYPES.SET_RATE_US_THANKS_MODAL_VISIBILITY);

const setRateUsLikeCounter = createAction(TYPES.INCREMENT_RATE_US_LIKE_COUNTER);
const setShowedOnHome = createAction(TYPES.SET_SHOWED_ON_HOME);
const setRated = createAction(TYPES.SET_RATED);
const setTrustPilotReviewLink = createAction(TYPES.SET_TRUST_PILOT_REVIEW_LINK);
const setTrustPilotPromoFreeMinutes = createAction(TYPES.SET_TRUST_PILOT_PROMO_FREE_MINUTES);
const setRating = createAction(TYPES.SET_RATING);

const rateUsOptions = {
  AppleAppID: GENERAL.IOS_APP_ID,
  GooglePackageName: GENERAL.BUNDLE_ID,
  preferInApp: true,
  openAppStoreIfInAppFails: false,
  preferredAndroidMarket: AndroidMarket.Google,
  OtherAndroidURL: GENERAL.STORE_LINK,
};

export const showRateUs = (feature: FEATURES, forced?: boolean) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { rateUs: config },
      },
    } = getState();

    if (!config?.enabled && !forced) {
      return false;
    }

    Analytics.track('RateUs_Show', { feature, isCustom: config.isCustom });

    if (!config.isCustom && Platform.OS !== 'web') {
      Analytics.track('RateUs_ShowNativeMobile');
      Rate.rate(rateUsOptions);

      if (feature === FEATURES.ASTRO_CALENDAR) {
        dispatch(addActiveTodo({ type: ASTRO_CALENDAR_TODO_TYPE.RATE_US }));
      }
      return false;
    }

    dispatch(generateTrustPilotReviewLink());
    dispatch(showModal(MODALS.RATE_US, { feature }));
  };
};

export const showRateUsOnDashboard = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      modals: { activeModal },
      rateUs: { showedOnHome, rated },
      remoteConfig: {
        remoteConfigParams: { rateUs: config },
      },
    } = getState();

    const sessionNumber = (Analytics?.getSessionNumber() ?? 0) + 1;

    if (sessionNumber < config?.showOnHomeSession) {
      return false;
    }

    if (showedOnHome || rated) {
      return false;
    }

    if (activeModal?.length) {
      return false;
    }

    const delay = +config?.showOnHomeDelay * 1000 || 0;

    return _.delay(() => {
      const currentRoute = getState().navigation.currentRoute;

      if (!ROUTES.RATE_US_EXCLUDE_ROUTES.includes(currentRoute)) {
        dispatch(showRateUs(FEATURES.DASHBOARD));
        dispatch(setShowedOnHome());
      }
    }, delay);
  };
};

export const showRateUsAfterLikePress = () => {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    dispatch(setRateUsLikeCounter());
    const {
      rateUs: { likeCounter },
      remoteConfig: {
        remoteConfigParams: { rateUs },
      },
    } = getState();

    // for likes don't need to check session
    if (!dispatch(isRateUsAvailable(true))) {
      return false;
    }

    if (likeCounter >= rateUs?.likesCountToShow) {
      dispatch(setRated());
      return _.delay(() => dispatch(showRateUs(FEATURES.LIKES)), 1000);
    }
  };
};

export const isRateUsAvailable = (noSessionCheck = false) => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      modals: { activeModal },
      rateUs: { rated },
      remoteConfig: {
        remoteConfigParams: { rateUs: config },
      },
    } = getState();
    const sessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;

    if (!config?.enabled) {
      return false;
    }

    if (!noSessionCheck && sessionNumber < config?.sessionStart) {
      return false;
    }

    if (rated) {
      return false;
    }

    if (activeModal?.length) {
      return false;
    }

    return true;
  };
};

export function requestReviewOnOnboarding(step: string) {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const {
      modals: { activeModal },
      remoteConfig: {
        remoteConfigParams: { rateUs: config },
      },
    } = getState();

    if (activeModal?.length) {
      return false;
    }

    if (!config?.enabled) {
      return false;
    }

    if (step === config?.onboardingStep?.toUpperCase()) {
      dispatch(showRateUs(FEATURES.ONBOARDING));
    }
  };
}

export function requestSupport() {
  return async function (_dispatch: AppDispatch, getState: AppGetState) {
    const {
      remoteConfig: {
        remoteConfigParams: { supportEmail },
      },
      billing: { purchased, transactions: data, purchaseDate, webTransactions },
    } = getState();

    try {
      const idfm = await DeviceProps.getIDFM();
      const deviceProps = await DeviceProps.fetch();

      const deviceName = getModel();
      const productId = !!data && data.length > 0 ? data[data.length - 1]?.productId : '';
      const subject = `${GENERAL.SUPPORT_TOPIC} ${idfm}`;

      const productFromWeb: any = !!webTransactions && webTransactions.length > 0 ? webTransactions[webTransactions.length - 1] : {};

      const { external_id, created_at: webCreatedAtSub } = productFromWeb ?? {};

      const body =
        Platform.OS !== 'web'
          ? `
        App Version: ${getVersion()};
        Device name: ${deviceName};
        Platform: ${Platform.OS}; 
        Paid: ${purchased ? '+' : '-'};
        Product ID: ${productId};
        Tracker: 'app';
        Subscription date: ${purchaseDate ? moment(purchaseDate).format('LLLL') : '-'}
        Customer ID: ${idfm};
        * Disclaimer: This data will help us to process your request in timely manner. Please do not delete it.
        `
          : `
        App Version: ${deviceProps.app_version};
        Device name: ${deviceProps.device_type} ${deviceProps.device_model} ${deviceProps.browser_name};
        Platform: ${Platform.OS}; 
        Paid: ${purchased ? '+' : '-'};
        Product ID: ${external_id ? external_id : '-'};
        Tracker: 'web';
        Subscription date: ${webCreatedAtSub ? moment(webCreatedAtSub).format('YYYY-MM-DD HH-mm-ss Z') : '-'}
        Customer ID: ${idfm};
        * Disclaimer: This data will help us to process your request in timely manner. Please do not delete it.
        `;

      const query = queryString.stringify({
        subject,
        body,
      });

      await Linking.openURL(`mailto:${supportEmail}?${query}`);
    } catch (error) {
      console.log('rate-us [requestSupport] Request Support Error', error);
    }
  };
}

export const rate = (rating: number, feature?: string) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      rateUs: { trustPilotReviewLink, trustPilotPromoFreeMinutes },
      modals: { params },
      remoteConfig: {
        remoteConfigParams: { rateUs: config, trustPilotPromoConfig },
      },
    } = getState();

    dispatch(setRateUsThanksModalVisibility(false));
    dispatch(setRated());
    dispatch(setRating(rating));

    feature = feature || params?.feature;
    Analytics.trackEvent('RateUs', 'Rate', { rating, feature });

    if (Platform.OS === 'web' && rating >= config?.minRateToTrustPilot) {
      dispatch(closeCurrentModal());

      if (trustPilotPromoConfig?.enabled && trustPilotPromoFreeMinutes) {
        dispatch(showModal(MODALS.RATE_TRUST_PILOT));
        return true;
      }

      Analytics.track('RateUs_RedirectToTrustPilot');
      // trustPilotReviewLink генерируется в useEffect кастомной модалки rateUs
      const link = trustPilotReviewLink?.length ? trustPilotReviewLink : GENERAL.TRUST_PILOT_URL;
      window.open(link, '_blank');
    }

    if (Platform.OS !== 'web' && rating > 4) {
      _.delay(() => {
        Analytics.track('RateUs_ShowNativeMobile');
        Rate.rate(rateUsOptions);
      }, config?.nativeDelay * 1000);
      return true;
    }

    return false;
  };
};

export const generateTrustPilotReviewLink = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { rateUs: config },
      },
    } = getState();

    if (Platform.OS !== 'web' || !config?.enabled) {
      return false;
    }

    try {
      const redirectUrl = window.location.origin + GENERAL.TRUST_REDIRECT_URI;
      const response = await generateTrustPilotLink(redirectUrl);

      if (response?.url?.length) {
        Analytics.track('TrustPilot_LinkGenerateSuccess');
        dispatch(setTrustPilotReviewLink(response.url));
      }
    } catch (e) {
      const error = isErrorWithMassage(e) ? e.message : String(e);
      Analytics.track('TrustPilot_LinkGenerateError', { error });
      console.log('> Generate Trust Pilot review link error', e);
    }
  };
};

export const initTrustPilotPromoCode = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      rateUs: { rated },
      remoteConfig: {
        remoteConfigParams: { rateUs: rateUsConfig, trustPilotPromoConfig },
      },
    } = getState();

    if (Platform.OS !== 'web' || !rateUsConfig?.enabled || rated) {
      return false;
    }

    try {
      const { enabled, promocode } = trustPilotPromoConfig || {};

      if (!enabled || !promocode?.length) {
        return false;
      }

      const result = await getPromocode(promocode);

      if (result?.seconds) {
        const minutes = formatMinutes(result.seconds);
        dispatch(setTrustPilotPromoFreeMinutes(minutes));
      }
    } catch (error) {
      console.log('> [ERROR] Get TrustPilot PromoCode info error', error);
    }
  };
};

export const initTrustPilot = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { rateUs: config },
      },
    } = getState();

    if (Platform.OS !== 'web' || !config?.enabled) {
      return false;
    }

    dispatch(generateTrustPilotReviewLink());
    dispatch(initTrustPilotPromoCode());
  };
};

export const handleTrustPilotReviewSuccess = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { trustPilotPromoConfig },
      },
    } = getState();

    if (trustPilotPromoConfig?.enabled && trustPilotPromoConfig?.promocode) {
      dispatch(handleExternalTrigger(trustPilotPromoConfig.promocode));
    }
  };
};

export const tryToRateUs = (rating: number) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      rateUs: { rated },
      remoteConfig: {
        remoteConfigParams: { rateUs: config },
      },
    } = getState();

    if (config?.enabled && !rated) {
      dispatch(rate(rating, 'advisor_review'));
    }
  };
};

export const showRateUsAfterFeature = (feature: FEATURES) => {
  return (dispatch: AppDispatch) => {
    if (!dispatch(isRateUsAvailable())) {
      return false;
    }

    dispatch(setRated());
    dispatch(showRateUs(feature));
  };
};
