import { createAction } from 'redux-actions';
import dayjs from 'dayjs';
import { getTransits, getTransitDuration } from '@wowmaking/birthchart';
import type { Transit, TransitDuration } from '@wowmaking/birthchart';

import type { AppThunk } from 'store';
import { t, t2 } from 'localization';
import { getApproximateLocation } from 'api/location';
import { getAspectsContent, getTransitsContent } from 'api/transits';
import type { PlaceValue } from 'interfaces/birth-chart';
import { getISODate } from 'utils/date';
import { upperCase } from 'utils/strings';

import { getAstroInputs } from '../birth-chart/actions';

import { TYPES } from './types';
import type { SetTransitsDataPayload, SetTransitsContentPayload, AstroeventTransit } from './types';

export const setCurrentLocation = createAction<PlaceValue>(TYPES.SET_CURRENT_LOCATION);
export const setTransitsData = createAction<SetTransitsDataPayload>(TYPES.SET_TRANSITS_DATA);
export const setTransitsContent = createAction<SetTransitsContentPayload>(TYPES.SET_TRANSITS_CONTENT);
export const setAstroeventTransit = createAction<AstroeventTransit>(TYPES.SET_ASTROEVENT_TRANSIT);

/* Init */

export const loadTransitsData = (): AppThunk<Promise<void>> => {
  return async dispatch => {
    const { birth, current } = await dispatch(getAstroInputs());
    const res = await getTransits({ birth, current });

    if (res.success) {
      const { currentPlanets, dailyTransits, nextTransits } = res.data;

      dispatch(
        setTransitsData({
          currentPlanets,
          dailyTransits,
          nextTransits,
        }),
      );
    }
  };
};

export const loadTransitsContent = (): AppThunk<Promise<void>> => {
  return async dispatch => {
    const [transitsContent, aspectsContent] = await Promise.all([getTransitsContent(), getAspectsContent()]);

    dispatch(
      setTransitsContent({
        transitsContent,
        aspectsContent,
      }),
    );
  };
};

/* Astro Event */

export const generateAstroeventTransitData = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const {
      transits: {
        astroeventTransit: { transit: currentTransit, connectionCounter = 0 },
        dailyTransits,
      },
      astrologers: {
        modals: {
          astroeventCounters: { resetCount },
        },
      },
    } = getState();

    const transits = dailyTransits?.[getISODate()]?.short || [];
    const transit = getAstroeventTransit(transits, currentTransit, resetCount > connectionCounter);

    if (!transit) {
      return;
    }

    const { natalPlanet, aspect, transitPlanet } = transit;
    const planet = t(`SINGS.PLANETS.${upperCase(transitPlanet.name)}`);
    const natalPlanetName = t(`SINGS.PLANETS.${upperCase(natalPlanet.name)}`);
    const aspectName = t(`TRANSITS.ASPECTS_NAMES.${upperCase(aspect.name)}`);
    const title = `${planet} ${aspectName} ${t('TRANSITS.DESCRIPTION.ARTICLE')} ${natalPlanetName}`;

    const description = t2(`TRANSITS.ASPECTS.${upperCase(aspect.name)}`, {
      transitPlanet: transitPlanet.name,
      natalPlanet: natalPlanet.name,
    });

    const durationData = await dispatch(loadTransitDurationData(transit));

    if (durationData) {
      const { start, end } = durationData;

      const duration = t(`TRANSITS.DURATION`, {
        start: dayjs(start).format('MMM DD, YYYY'),
        end: dayjs(end).format('MMM DD, YYYY'),
      });

      dispatch(setAstroeventTransit({ title, description, duration, transit, connectionCounter: resetCount }));
    }
  };
};

const getAstroeventTransit = (transits: Transit[] = [], currentTransit: Transit, wasConnection: boolean): Transit | null => {
  if (!transits?.length) {
    return null;
  }

  if (!currentTransit) {
    return transits[0];
  }

  let transitIndex = transits.findIndex(item => Object.entries(item).toString() === Object.entries(currentTransit).toString());

  if (transitIndex === -1 || !wasConnection) {
    return currentTransit;
  }

  transitIndex += 1;

  if (transitIndex >= transits.length) {
    return transits[0];
  }

  return transits[transitIndex];
};

/* Helpers */

let currentLocationPromise: ReturnType<typeof getApproximateLocation> | null = null;

export const getCurrentLocation = (): AppThunk<Promise<PlaceValue>> => {
  return async (dispatch, getState) => {
    const {
      birthChart: { birthPlace },
      transits: { currentLocation },
    } = getState();

    if (currentLocation) {
      return currentLocation;
    }

    if (currentLocationPromise === null) {
      currentLocationPromise = getApproximateLocation();
    }

    const location = await currentLocationPromise;
    currentLocationPromise = null;

    if (location) {
      const newCurrentLocation = {
        name: location.time_zone,
        lat: location.latitude,
        lon: location.longitude,
      };

      dispatch(setCurrentLocation(newCurrentLocation));

      return newCurrentLocation;
    }

    return birthPlace;
  };
};

export const loadTransitDurationData = (transit: Transit): AppThunk<Promise<TransitDuration | null>> => {
  return async dispatch => {
    const { birth, current } = await dispatch(getAstroInputs());
    const res = await getTransitDuration({ transit, transitInputs: current, birth });
    return res.success ? res.data : null;
  };
};
