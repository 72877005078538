import type { PlanetaryCompatibilityItem } from '@wowmaking/birthchart';
import type { ZodiacSign } from '@wowmaking/birthchart';

import { PlaceValue } from 'interfaces/birth-chart';
import { ISODate } from 'interfaces/date';
import { BIRTHCHARTS_COM8TY_ONB_PAGES_IDS, PROFILE_PARAMS } from 'screens/birth-charts-compatibility/constants';
import { DailyLoveHoroscopeIds } from 'screens/birth-charts-compatibility/interfaces';

export enum TYPES {
  SET_WIDGET_VISIBLE = 'BIRTH_CHARTS_COM8TY/SET_WIDGET_VISIBLE',
  SET_ONBOARDING_IS_COMPLETED = 'BIRTH_CHARTS_COM8TY/SET_ONBOARDING_IS_COMPLETED',
  SET_ONBOARDING_FLOW = 'BIRTH_CHARTS_COM8TY/SET_ONBOARDING_FLOW',
  SET_ONBOARDING_STEP = 'BIRTH_CHARTS_COM8TY/SET_ONBOARDING_STEP',
  SET_LOVE_HOROSCOPE_ACTIVE_ID = 'BIRTH_CHARTS_COM8TY/SET_LOVE_HOROSCOPE_ACTIVE_ID',
  SET_LOVE_HOROSCOPES_PLAN = 'BIRTH_CHARTS_COM8TY/SET_LOVE_HOROSCOPES_PLAN',
  SET_RESULT = 'BIRTH_CHARTS_COM8TY/SET_RESULT',
  SET_COMPATIBILITY_INSIGHTS = 'BIRTH_CHARTS_COM8TY/SET_COMPATIBILITY_INSIGHTS',
  UPDATE_PARTNER_DATA = 'BIRTH_CHARTS_COM8TY/UPDATE_PARTNER_DATA',
  SET_PLANETARY_REPORT = 'BIRTH_CHARTS_COM8TY/SET_PLANETARY_REPORT',
}

export interface CompatibilityProfileData {
  [PROFILE_PARAMS.NAME]: string | null;
  [PROFILE_PARAMS.DATE]: string | null;
  [PROFILE_PARAMS.PLACE]: PlaceValue | null;
  [PROFILE_PARAMS.TIME]: string | null;
}

export interface CompatibilityResult {
  aspects: [
    {
      id: 'love' | 'sex' | 'family' | 'friendship';
      percent: number;
    },
  ];
  compatibilityLevelPercent: number;
  signs: {
    user: ZodiacSign;
    partner: ZodiacSign;
  };
}

export interface State {
  compatibilityInsights: string | null;
  isWidgetVisible: boolean;
  loveHoroscopesActiveId: DailyLoveHoroscopeIds | null;
  loveHoroscopesUpdateDate: ISODate | null;
  loveHoroscopesPlan: DailyLoveHoroscopeIds[];
  onboardingFlow: BIRTHCHARTS_COM8TY_ONB_PAGES_IDS[];
  onboardingIsCompleted: boolean;
  onboardingStep: BIRTHCHARTS_COM8TY_ONB_PAGES_IDS | null;
  partnerData: CompatibilityProfileData;
  planetaryReport: PlanetaryCompatibilityItem[];
  result: CompatibilityResult | null;
}

interface SetWidgetVisible {
  type: typeof TYPES.SET_WIDGET_VISIBLE;
  payload: boolean;
}

interface SetOnboardingStepAction {
  type: typeof TYPES.SET_ONBOARDING_STEP;
  payload: BIRTHCHARTS_COM8TY_ONB_PAGES_IDS;
}

interface SetOnboardingFlow {
  type: typeof TYPES.SET_ONBOARDING_FLOW;
  payload: BIRTHCHARTS_COM8TY_ONB_PAGES_IDS[];
}

interface SetOnboardingCompleted {
  type: typeof TYPES.SET_ONBOARDING_IS_COMPLETED;
}

interface SetResult {
  type: typeof TYPES.SET_RESULT;
  payload: CompatibilityResult;
}

interface UpdatePartnerData {
  type: typeof TYPES.UPDATE_PARTNER_DATA;
  payload: CompatibilityProfileData;
}
interface SetLoveHoroscopesPlan {
  type: typeof TYPES.SET_LOVE_HOROSCOPES_PLAN;
  payload: DailyLoveHoroscopeIds[];
}
interface SetLoveHoroscopeActiveId {
  type: typeof TYPES.SET_LOVE_HOROSCOPE_ACTIVE_ID;
  payload: DailyLoveHoroscopeIds;
}

interface SetCompatibilityInsights {
  type: typeof TYPES.SET_COMPATIBILITY_INSIGHTS;
  payload: string | null;
}

interface SetPlanetaryReport {
  type: typeof TYPES.SET_PLANETARY_REPORT;
  payload: PlanetaryCompatibilityItem[];
}

export type ActionTypes =
  | SetWidgetVisible
  | SetOnboardingStepAction
  | SetOnboardingCompleted
  | SetOnboardingFlow
  | SetLoveHoroscopesPlan
  | SetLoveHoroscopeActiveId
  | SetResult
  | SetCompatibilityInsights
  | UpdatePartnerData
  | SetPlanetaryReport;
