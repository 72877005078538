import { createAction } from 'redux-actions';
import { difference } from 'lodash';
import dayjs from 'dayjs';
import { getAstroCalendarTransits } from '@wowmaking/birthchart';

import Analytics from 'analytics';
import { t } from 'localization';
import { getAstroCalendarDays, postAstroCalendarDays, putAstroCalendarDays, getRetrogrades, addTime } from 'api/astro-calendar';
import type { ISODate } from 'interfaces/date';
import * as MODALS from 'constants/modals';
import * as ROUTES from 'constants/routes';
import { ASTRO_CALENDAR_PDF_GUIDE_BONUS } from 'constants/modals';
import type { AppDispatch, AppGetState, AppThunk } from 'store';
import { navigate } from 'store/navigation/actions';
import { getAvailableTime } from 'store/astrologers/time/actions';
import { showModal } from 'store/modals/actions';
import { FEATURES } from 'store/rate-us/types';
import { showRateUs } from 'store/rate-us/actions';
import { getProfile } from 'api/profile';
import { ASTRO_CALENDAR_BONUS_TYPE, ASTRO_CALENDAR_EVENT_TYPE, RETROGRADE_STORIES_CATEGORY_SLUG } from 'screens/astro-calendar/constants';
import {
  convertDayFromDataToDB,
  convertDaysFromConfigToDB,
  convertDaysFromDBToData,
  getDayBonusesAvailable,
  getAnalyticsParams,
} from 'screens/astro-calendar/utils';
import type {
  AstroCalendarDBDay,
  AstroCalendarDataDay,
  AstroCalendarDataBonus,
  AstroCalendarDataTodo,
  AstroEvent,
  RetrogradeDBItem,
} from 'screens/astro-calendar/interfaces';
import { getISODate, getISODatesRange, getStartEndFromDatesRange } from 'utils/date';
import { ASTRO_CALENDAR_TODO_TYPE, ASTRO_CALENDAR_PLACE, ASTRO_CALENDAR_NAVIGATE_TODO_DATA } from 'screens/astro-calendar/constants';
import { loadStories } from 'store/stories/actions';
import { setUserParams } from 'store/profile/actions';

import { getAstroInputs } from '../birth-chart/actions';

import { TYPES } from './types';

const updateData = createAction(TYPES.UPDATE_DATA);
const setRetrogradesContent = createAction(TYPES.SET_RETROGRADES_CONTENT);
export const setTutorialShown = createAction(TYPES.SET_TUTORIAL_SHOWN);
export const addActiveTodo = createAction(TYPES.ADD_ACTIVE_TODO);
export const resetActiveTodos = createAction(TYPES.RESET_ACTIVE_TODOS);
export const addPendingTodo = createAction(TYPES.ADD_PENDING_TODO);
export const deletePendingTodo = createAction(TYPES.DELETE_PENDING_TODO);
export const setActiveNotification = createAction(TYPES.SET_ACTIVE_NOTIFICATION);
export const setOriginPlace = createAction(TYPES.SET_ORIGIN_PLACE);

/* General */

export const loadAstroCalendarData = (params: { start: ISODate; end: ISODate }): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astroCalendar: { data },
      profile: {
        profileData: { userParams },
      },
    } = getState();

    const firstDay = userParams?.astro_calendar_first_day;

    if (!firstDay) {
      dispatch(setUserParams({ astro_calendar_first_day: getISODate() }));
    }

    const minDate = dayjs(firstDay);
    const maxDate = dayjs().add(1, 'month').endOf('month');

    const startDate = dayjs(params.start).isBefore(minDate) ? getISODate(minDate) : params.start;
    const endDate = dayjs(params.end).isAfter(maxDate) ? getISODate(maxDate) : params.end;

    const theDatesWeNeed = getISODatesRange(startDate, endDate);
    const theDatesWeHave = Object.keys(data) as ISODate[];
    const theDatesToFetch = difference(theDatesWeNeed, theDatesWeHave);

    if (!theDatesToFetch.length) {
      return;
    }

    const { start, end } = getStartEndFromDatesRange(theDatesToFetch);

    const theDaysFromDB = await getAstroCalendarDays({
      startDate: start,
      endDate: end,
    });

    const theDatesWeFetched = theDaysFromDB.map(day => day.date);
    const theDatesWeStillNeed = difference(theDatesToFetch, theDatesWeFetched);

    await Promise.all([dispatch(handleDBDays(theDaysFromDB)), dispatch(generateDBDaysFromConfig(theDatesWeStillNeed))]);
    dispatch(checkAutoCompletionTodoForFirstDay());
  };
};

export const generateDBDaysFromConfig = (dates: ISODate[], reset = false): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    if (!dates.length) {
      return;
    }

    const {
      profile: {
        profileData: { userParams },
      },
      remoteConfig: {
        remoteConfigParams: { astroCalendarConfig },
      },
    } = getState();

    const dbDays = convertDaysFromConfigToDB({
      config: astroCalendarConfig,
      dates,
      firstDay: userParams?.astro_calendar_first_day || getISODate(),
    });

    await Promise.all([dispatch(handleDBDays(dbDays)), reset ? putAstroCalendarDays(dbDays) : postAstroCalendarDays(dbDays)]);
  };
};

export const handleDBDays = (days: AstroCalendarDBDay[]): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    if (!days.length) {
      return;
    }

    const {
      guides: { guides },
      profile: {
        profileData: { userParams },
      },
    } = getState();

    const {
      birth,
      current: { location: currentLocation },
    } = await dispatch(getAstroInputs());

    const [retrogradesContent, stories, transitsRes] = await Promise.all([
      dispatch(loadRetrogradesContent()),
      dispatch(loadStories()),
      getAstroCalendarTransits({
        dates: days.map(d => d.date),
        birth,
        currentLocation,
      }),
    ]);

    const transitsData = transitsRes.success ? transitsRes.data : {};

    dispatch(
      updateData(
        convertDaysFromDBToData(days, {
          transitsData,
          retrogradesContent,
          guides,
          userParams,
          stories,
        }),
      ),
    );
  };
};

export const saveDataDay = (updatedDay: AstroCalendarDataDay): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch) => {
    const { date } = updatedDay;
    dispatch(updateData({ [date]: updatedDay }));
    const updatedDBDay = convertDayFromDataToDB(updatedDay);
    await putAstroCalendarDays([updatedDBDay]);
  };
};

export const openAstroEvent = (event: AstroEvent): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const { type, date } = event;
    const {
      birthChart: { onboardingDone: isBirthChartOnboardingDone },
      stories: { stories },
    } = getState();

    switch (type) {
      case ASTRO_CALENDAR_EVENT_TYPE.LUNAR_PHASE: {
        navigate(ROUTES.LUNAR_CALENDAR, { date, place: ASTRO_CALENDAR_PLACE });
        break;
      }

      case ASTRO_CALENDAR_EVENT_TYPE.TRANSIT: {
        const navParams = {
          screen: ROUTES.BIRTH_CHART_DASHBOARD,
          params: {
            screen: ROUTES.BIRTH_CHART_TRANSITS,
            params: {
              date,
              place: ASTRO_CALENDAR_PLACE,
            },
          },
        };
        navigate(ROUTES.BIRTH_CHART, isBirthChartOnboardingDone ? navParams : undefined);
        break;
      }
      case ASTRO_CALENDAR_EVENT_TYPE.RETROGRADE_PLANET: {
        const retrogradeStoriesId = stories.find(story => story.slug === RETROGRADE_STORIES_CATEGORY_SLUG)?.id;

        if (retrogradeStoriesId) {
          navigate(ROUTES.STORIES, { id: retrogradeStoriesId, showOnlyOneCategory: true, backOnClose: true, place: ASTRO_CALENDAR_PLACE });
        }
        break;
      }
    }

    dispatch(setAstroEventsViewed(date));
  };
};

export const setAstroEventsViewed = (date: ISODate): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astroCalendar: { data },
    } = getState();

    const day = data[date];

    if (!day || day.astroEventsCompleted || dayjs().isBefore(dayjs(date), 'day')) {
      return;
    }

    await dispatch(
      saveDataDay({
        ...day,
        astroEventsCompleted: true,
      }),
    );
  };
};

export const loadRetrogradesContent = (): AppThunk<Promise<RetrogradeDBItem[]>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astroCalendar: { retrogradesContent },
    } = getState();

    if (retrogradesContent.length) {
      return retrogradesContent;
    }

    const response = await getRetrogrades();

    if (response.length) {
      dispatch(setRetrogradesContent(response));
    }

    return response;
  };
};

/* Todos */

export const startTodo = (todo: AstroCalendarDataTodo): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const { type, completed, date } = todo;

    const showInfoModal = () => {
      dispatch(
        showModal(MODALS.ASTRO_CALENDAR_CONTENT_INFO, {
          title: t('ASTRO_CALENDAR.INFO_MODALS.TODO_COMPLETED.TITLE'),
          text: t('ASTRO_CALENDAR.INFO_MODALS.TODO_COMPLETED.SUBTITLE'),
        }),
      );
    };

    dispatch(setAstroEventsViewed(date));
    dispatch(resetActiveTodos());

    switch (type) {
      case ASTRO_CALENDAR_TODO_TYPE.NAVIGATE: {
        const { contentId } = todo;
        const { route, params } = ASTRO_CALENDAR_NAVIGATE_TODO_DATA[contentId].navigation;
        const navParams = { ...params, place: ASTRO_CALENDAR_PLACE };

        if (!completed) {
          dispatch(addPendingTodo(todo));
        }
        navigate(route, navParams);
        break;
      }

      case ASTRO_CALENDAR_TODO_TYPE.RATE_US: {
        if (!completed) {
          dispatch(addPendingTodo(todo));
          const rated = getState().rateUs.rated;
          if (rated) {
            dispatch(showModal(MODALS.ASTRO_CALENDAR_RATE_US, { feature: FEATURES.ASTRO_CALENDAR }));
          } else {
            dispatch(showRateUs(FEATURES.ASTRO_CALENDAR, true));
          }
        } else {
          showInfoModal();
        }
        break;
      }

      case ASTRO_CALENDAR_TODO_TYPE.DAILY_FORECAST: {
        if (!completed) {
          dispatch(addPendingTodo(todo));
          dispatch(showModal(MODALS.MESSENGERS, { place: ASTRO_CALENDAR_PLACE }));
        } else {
          showInfoModal();
        }
        break;
      }

      case ASTRO_CALENDAR_TODO_TYPE.PWA: {
        if (!completed) {
          dispatch(addPendingTodo(todo));
          dispatch(showModal(MODALS.PWA_INSTRUCTION, { place: ASTRO_CALENDAR_PLACE }));
        } else {
          showInfoModal();
        }
        break;
      }
    }
  };
};

export const setTodoCompleted = (todo: AstroCalendarDataTodo): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { date, id, completed, type } = todo;

    if (completed) {
      return;
    }

    const {
      astroCalendar: { data },
      profile: {
        profileData: { userParams },
      },
    } = getState();

    const day = data[date];

    if (!day) {
      return;
    }

    dispatch(deletePendingTodo(todo));

    await dispatch(
      saveDataDay({
        ...day,
        todos: day.todos.map(item => (item.id === id ? { ...item, completed: true } : item)),
      }),
    );

    Analytics.trackEvent('AstroCalendar_Todo', 'Completed', getAnalyticsParams({ type, firstDay: userParams?.astro_calendar_first_day, date }));
    dispatch(checkIsBonusAvailable(date));
  };
};

export const checkIsPwaInstalled = (): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const pendingTodos = getState().astroCalendar.pendingTodos;
    const pwaTodo = pendingTodos.find(todo => todo.type === ASTRO_CALENDAR_TODO_TYPE.PWA);
    try {
      const profileData = await getProfile();
      if (!!profileData?.profile?.data?.pwa_installed && !!pwaTodo) {
        dispatch(setTodoCompleted(pwaTodo));
      }
    } catch (error) {
      console.log('[ERROR LOAD PROFILE DATA]', error);
    }
  };
};

/* Bonuses */

export const redeemBonus = (bonus: AstroCalendarDataBonus): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { type, completed } = bonus;

    switch (type) {
      case ASTRO_CALENDAR_BONUS_TYPE.MINUTES: {
        const { code } = bonus;
        navigate(ROUTES.ADVISORS, { place: ASTRO_CALENDAR_PLACE });
        if (!completed) {
          const result = await addTime({ code });
          if (result) {
            await dispatch(getAvailableTime());
          } else {
            return; // Skip setting bonus to completed
          }
        }
        break;
      }

      case ASTRO_CALENDAR_BONUS_TYPE.GUIDE: {
        const { contentId } = bonus;
        const guides = getState().guides.guides;
        const guideId = guides.find(guide => guide.slug === contentId)?.id;
        if (guideId) {
          navigate(ROUTES.GUIDE_PAGE, { guideId, place: ASTRO_CALENDAR_PLACE });
        }
        break;
      }

      case ASTRO_CALENDAR_BONUS_TYPE.PDF_GUIDE: {
        const { contentId } = bonus;
        dispatch(
          showModal(ASTRO_CALENDAR_PDF_GUIDE_BONUS, {
            contentId,
          }),
        );
        break;
      }
    }

    await dispatch(setBonusReceived(bonus));
  };
};

export const setBonusReceived = (bonus: AstroCalendarDataBonus): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { date, id, completed } = bonus;

    if (completed) {
      return;
    }

    const {
      astroCalendar: { data },
    } = getState();

    const day = data[date];

    if (!day) {
      return;
    }

    await dispatch(
      saveDataDay({
        ...day,
        bonuses: day.bonuses.map(item => (item.id === id ? { ...item, completed: true } : item)),
      }),
    );
  };
};

export const checkIsBonusAvailable = (date: ISODate): AppThunk => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astroCalendar: { data },
      profile: {
        profileData: { userParams },
      },
    } = getState();

    const day = data[date];

    if (!day) {
      return;
    }

    if (getDayBonusesAvailable(day)) {
      day.bonuses.map(bonus => {
        Analytics.trackEvent(
          'AstroCalendar_Bonus',
          'Available',
          getAnalyticsParams({ type: bonus.type, firstDay: userParams?.astro_calendar_first_day, date }),
        );
      });
    }
  };
};

export const showNotification = (todo: AstroCalendarDataTodo): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    if (todo.type === ASTRO_CALENDAR_TODO_TYPE.NAVIGATE) {
      const notificationConfig = getState().remoteConfig.remoteConfigParams?.astroCalendarNotificationConfig;
      const currentSessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;

      if (notificationConfig?.enabled && currentSessionNumber >= (notificationConfig?.sessionStart ?? 1)) {
        dispatch(setActiveNotification(todo));
      }
    }
  };
};

export const resetProgress = (): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      profile: {
        profileData: { userParams },
      },
    } = getState();

    const startDate = getISODate(dayjs(userParams?.astro_calendar_first_day));
    const endDate = getISODate(dayjs().add(1, 'month').endOf('month'));
    const theDatesWeNeed = getISODatesRange(startDate, endDate);

    dispatch(generateDBDaysFromConfig(theDatesWeNeed, true));
  };
};

export const setBonusAnimationShown = (date: ISODate): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astroCalendar: { data },
    } = getState();

    const day = data[date];

    if (!day || day.bonusesAnimationShown) {
      return;
    }

    await dispatch(
      saveDataDay({
        ...day,
        bonusesAnimationShown: true,
      }),
    );
  };
};

export const checkAutoCompletionTodoForFirstDay = (): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astroCalendar: { data },
      profile: {
        profileData: { userParams },
      },
      remoteConfig: {
        remoteConfigParams: { astroCalendarCompleteFirstTodoEnabled },
      },
    } = getState();

    const firstDay = userParams?.astro_calendar_first_day;
    const currentDate = getISODate();

    if (dayjs(firstDay).isSame(dayjs(currentDate), 'day') && astroCalendarCompleteFirstTodoEnabled) {
      const dayTodo = data[currentDate]?.todos[0];

      if (dayTodo && !dayTodo.completed) {
        dispatch(setAstroEventsViewed(currentDate));
        dispatch(setTodoCompleted(dayTodo));
      }
    }
  };
};
