import { createSelector } from 'reselect';

import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components';
import { RootState } from 'store';

const getTriggerNotifications = (state: RootState) => state.astrologers.notifications.triggerNotifications;

export const selectTriggerNotificationSeconds = createSelector(
  [getTriggerNotifications, (_: RootState, notification: EXTERNAL_TRIGGER_NOTIFICATIONS) => notification],
  (notifications, notification) => {
    return notifications?.find(({ type }) => type === notification)?.seconds;
  },
);
