import { ZodiacSign } from '@wowmaking/birthchart';

export enum TODAY_MATCHES_IDS {
  CAREER = 'career',
  FRIENDSHIP = 'friendship',
  LOVE = 'love',
  SEX = 'sex',
}

export interface TodayMatchesItem {
  sign: ZodiacSign;
}

export type TodayMatchesItemKind = 'best_today' | 'worst_today';

export type TodayMatches = {
  [key in TODAY_MATCHES_IDS]: {
    [type in TodayMatchesItemKind]: TodayMatchesItem[];
  };
};

export enum TODAY_FEATURES_IDS {
  COLOR = 'color',
  NUMBER = 'number',
  TIME = 'time',
}

export interface TodayColor {
  hex: string;
  title: string;
  description: string;
}

export interface TodayNumber {
  value: number;
  description: string;
}

export interface TodayTime {
  value: string;
  description: string;
}

export interface TodayFeatures {
  [TODAY_FEATURES_IDS.COLOR]: TodayColor;
  [TODAY_FEATURES_IDS.NUMBER]: TodayNumber;
  [TODAY_FEATURES_IDS.TIME]: TodayTime;
}

export interface GetTodayFeaturesResponse {
  today_features: TodayFeatures;
  today_matches: TodayMatches;
}
