import type { DailyTransitsData, NextTransitsData, EphemerisPlanet } from '@wowmaking/birthchart';

import type { AspectDescription, TransitDescription } from 'api/transits/interfaces';
import type { PlaceValue } from 'interfaces/birth-chart';

export enum TYPES {
  SET_CURRENT_LOCATION = 'TRANSITS/SET_CURRENT_LOCATION',
  SET_TRANSITS_DATA = 'TRANSITS/SET_TRANSITS_DATA',
  SET_TRANSITS_CONTENT = 'TRANSITS/SET_TRANSITS_CONTENT',
  SET_ASTROEVENT_TRANSIT = 'TRANSITS/SET_ASTROEVENT_TRANSIT',
}

export interface AstroeventTransit {
  title: string;
  description: string;
  duration: string;
  connectionCounter: number;
  transit: any;
}

export interface State {
  currentLocation: PlaceValue | null;
  currentPlanets: EphemerisPlanet[];
  dailyTransits: DailyTransitsData | null;
  nextTransits: NextTransitsData | null;
  transitsContent: TransitDescription[];
  aspectsContent: AspectDescription[];
  astroeventTransit: AstroeventTransit;
}

interface SetCurrentLocation {
  type: typeof TYPES.SET_CURRENT_LOCATION;
  payload: PlaceValue;
}

interface SetTransitsData {
  type: typeof TYPES.SET_TRANSITS_DATA;
  payload: SetTransitsDataPayload;
}

export interface SetTransitsDataPayload {
  currentPlanets: EphemerisPlanet[];
  dailyTransits: DailyTransitsData;
  nextTransits: NextTransitsData;
}

interface SetTransitsContent {
  type: typeof TYPES.SET_TRANSITS_CONTENT;
  payload: SetTransitsContentPayload;
}

export interface SetTransitsContentPayload {
  transitsContent: TransitDescription[];
  aspectsContent: AspectDescription[];
}

interface SetAstroeventTransit {
  type: typeof TYPES.SET_ASTROEVENT_TRANSIT;
  payload: AstroeventTransit;
}

export type ActionTypes = SetCurrentLocation | SetTransitsData | SetTransitsContent | SetAstroeventTransit;
