import { WEB_REPORTS_PRODUCTS } from 'constants/billing';

import BIRTH_CHART from './img/birth-chart.png';
import COMPATIBILITY from './img/compatibility.png';
import { PersonalReports } from './interfaces';

export const PERSONAL_REPORTS: PersonalReports = {
  [WEB_REPORTS_PRODUCTS.BIRTH_CHART]: {
    title: 'BIRTH_CHART',
    image: BIRTH_CHART,
    pathName: 'birth-chart',
  },
  [WEB_REPORTS_PRODUCTS.COMPATIBILITY]: {
    title: 'COMPATIBILITY',
    image: COMPATIBILITY,
    pathName: 'compatibility',
  },
};
